import React, { useEffect, useContext, useRef } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import ImageSteps from "../../components/products/ImageSteps";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/safety-meetings.jpg";
import img1 from "../../assets/img/products/steps/Select relevant LFI from libray.jpg";
import img2 from "../../assets/img/products/steps/Watch together.jpg";
import img3 from "../../assets/img/products/steps/Discuss and act.jpg";
import img4 from "../../assets/img/products/steps/Summarise learning.jpg";

import { StaticImage } from "gatsby-plugin-image";

const SafetyMeetings = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  const steps = [
    {
      id: 1,
      img: img1,
      name: "Select relevant LFI from libray",
    },
    {
      id: 2,
      img: img2,
      name: "Watch together",
    },
    {
      id: 3,
      img: img3,
      name: "Discuss and act",
      instructions: "How can this happen in our job?",
    },
    {
      id: 4,
      img: img4,
      name: "Summarise learning",
    },
  ];

  return (
    <Layout>
      <Seo
        title='Safety Meetings | VIRSAT - Virtual Reality Safety Training'
        description='All too often, safety meetings are repetitive and may even have a “tick-the-box” flavour. VIRSAT can help you implement a structured process to ensure safety.'
      />
      <PageBanner title='Safety Meetings' subtitle='Create discussions' />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col'>
          <div className='column center fj-center'>
            <div className='image-container'>
              {/* <img src={image} alt='Pre-job discussions' /> */}
              <StaticImage
                src='../../assets/img/products/safety-meetings.jpg'
                alt='Safety Meetings'
                placeholder='blurred'
                objectFit
              />
            </div>
            <p className='reduce_width no-mar' style={{ marginTop: "30px" }}>
              All too often, safety meetings are repetitive and may even have a
              “tick-the-box” flavour. VIRSAT can help you implement a structured
              process to ensure safety meetings are engaging and add value.
            </p>
          </div>
          <ImageSteps steps={steps} />
        </div>
      </section>
      <Clients />
      <InteractiveVideo />
    </Layout>
  );
};

export default SafetyMeetings;
